import { CONTENTFUL_PREVIEW_SECRET, GTM_ID, IsPreview } from '@/shared/config';
import { useSearchParams } from 'next/navigation';
import { useEffect, type ComponentType } from 'react';
import TagManager from 'react-gtm-module';
import { SentryMonitoring } from '../../sentry.client.config';
import { SnowplowProvider } from '../context';
import { GeolocationProvider } from '../context/GeolocationContext';
import { ProfileContextProvider } from '../context/ProfileContext';

export function commonHOC<TProps extends object>(WrappedComponent: ComponentType<TProps>, componentName: string) {
	return function (props: TProps) {
		const searchParams = useSearchParams();

		const secret = searchParams.get('secret');
		const isDraft = secret ? secret === CONTENTFUL_PREVIEW_SECRET : IsPreview;

		useEffect(() => {
			SentryMonitoring.init(componentName);
			GTM_ID && TagManager.initialize({ gtmId: GTM_ID });
		}, []);

		const preview = isDraft && (
			<>
				<div
					style={{
						position: 'fixed',
						top: 0,
						left: 0,
						borderRadius: '.5em',
						padding: '.5em',
						margin: '.5em',
						userSelect: 'none',
						background: 'white',
						border: '1px solid black',
					}}
				>
					Contentful Preview Mode
				</div>
			</>
		);

		return (
			<>
				{preview}
				<ProfileContextProvider>
					<GeolocationProvider>
						{/* snowplow is used to collect engagement metrics */}
						<SnowplowProvider>
							<WrappedComponent {...props} />
						</SnowplowProvider>
					</GeolocationProvider>
				</ProfileContextProvider>
			</>
		);
	};
}
